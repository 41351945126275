<template>
  <div>
    <general-table
      ref="productsTable"
      :api-url="APIURL"
      :add-type="addType"
      :view-content="false"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :type="type"
      :columns="columns"
      :selectable="false"
      :guard="guard"
    >
      <template #cell(active)="data">
        <b-badge
          class="change-status"
          :variant="changeVaraint(data.item.active)"
        >
          {{ getTypes(data.item.active) }}
        </b-badge>
      </template>
      <template #cell(price)="data">
        <span :class="data.item.offer > 0 ? 'discounted-price' : ''">
          {{
            data.item.price
          }}</span>
        <sup
          v-if="data.item.offer > 0"
          class="conOfOffer"
        >-{{ data.item.offer }}%</sup>
        <span
          v-if="data.item.offer> 0"
          class="ml-1"
        >{{ data.item.net_price }}</span>
      </template>
      <template #cell(feathered)="data">
        <div
          :class="data.item.feathered === 1 ? 'text-success' : 'text-danger'"
        >
          {{ getFeatured(data.item.feathered) }}
        </div>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/products',
      addType: 'Add product',
      addComponentName: 'add-product',
      editComponent: 'edit-product',
      viewContent: true,
      type: 'page',
      columns: [
        { key: 'id', sortable: true },
        { key: 'product_images', label: 'Image' },
        { key: 'name', label: 'Name' },
        { key: 'price', label: 'Price' },
        { key: 'qty', label: 'Quantity' },
        { key: 'feathered', label: 'Featured' },
        { key: 'active', label: 'Active' },
        { key: 'actions' },
      ],
    }
  },
  methods: {
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type === 1) return 'success'

      return 'danger'
    },
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
    getTypes(type) {
      if (type === 1) {
        return 'Active'
      }
      if (type === 0) {
        return 'Not Active'
      }
      return 'Active'
    },
    getFeatured(type) {
      if (type === 1) {
        return 'Featured'
      }
      if (type === 0) {
        return 'Not Featured'
      }
      return 'Featured'
    },
  },
}
</script>

<style lang="scss" >

</style>
